import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import type { APIRequestData } from '@core/types/metrics';
import abbreviatedNumber from '@core/utils/abbreviatedNumber';

import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Sparkline from '@ui/Sparkline';
import Spinner from '@ui/Spinner';

import styles from './styles.module.scss';

interface Props {
  requestData?: APIRequestData;
}

const HEADER = 'API Requests';

const calculateTotalRequests = (data: number[]) => data.reduce((acc, el) => acc + el, 0);
const calculateErrorRate = ({ failure, success }) => {
  const calc = failure / success;
  // only show % up to 2 decimal places
  return calc >= 100 ? 100 : calc.toFixed(2);
};

const ErrorRate = ({ data }: { data: APIRequestData['data'] }) => {
  const success = calculateTotalRequests(data[0]);
  const failure = calculateTotalRequests(data[1]);
  const errorRate = calculateErrorRate({ failure, success });

  return (
    <Flex align="center" tag="div">
      <Flex gap={0} tag="span">
        <Flex align="center" gap={0} tag="span">
          <HTTPStatus iconOnly status={200} />
          {abbreviatedNumber(success)}
        </Flex>
        <Flex align="center" gap={0} tag="span">
          <HTTPStatus iconOnly status={500} />
          {abbreviatedNumber(failure)}
        </Flex>
      </Flex>
      <span>Error Rate: {errorRate}%</span>
    </Flex>
  );
};

const APIRequestGraph = ({ requestData }: Props) => {
  const bem = useClassy(styles, 'APIRequestGraph');

  const content = useMemo(() => {
    switch (true) {
      case !requestData || !requestData.data:
        return (
          <div className={bem('&')}>
            <header className={bem('-header')}>{HEADER}</header>
            <Spinner className={bem('&-loading')} size="lg" />
          </div>
        );
      case isEmpty(requestData?.data):
        return <div className={bem('&-empty')}></div>;
      default:
        return (
          <div className={bem('&')}>
            <header className={bem('-header')}>{HEADER}</header>
            <Sparkline className={bem('-graph')} data={requestData.data} labels={requestData.labels} maxWidth="230" />
            <ErrorRate data={requestData.data} />
          </div>
        );
    }
  }, [bem, requestData]);

  return content;
};

export default APIRequestGraph;
