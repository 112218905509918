import type { DateRangeOptions, HistoricalResponse, QueryOpts } from '@core/types/metrics';

export interface CustomerUsageData {
  explorer: {
    monthToDate: number;
    thirtyDay: number;
    twentyFourHour: number;
  };
  sdk: {
    monthToDate: number;
    thirtyDay: number;
    twentyFourHour: number;
  };
}

interface CustomerUsageState extends CustomerUsageData {
  limit: number;
  overLimit: boolean;
  paying: boolean;
}

export interface MetricsContextState {
  customerUsage: CustomerUsageState;
  dateRanges: DateRangeOptions | null;
  developmentData: boolean | null;
  endpoint: string | null;
  filters: string[];
  graph: {
    data: Partial<HistoricalResponse>;
    isLoading: boolean;
  };
  includeTryItNow: boolean | null;
  isFetchReady: Record<string, boolean>;
  isSetupComplete: boolean;
  isUsageReady: boolean;
  options: {
    graph: {
      groupByBase: string;
      groups: string[];
      noGroupByQuery?: string;
      query: string;
      type: string;
    };
    table: { columns: string[]; query: string };
  };
  query: URLSearchParams;
  routeKey?: string;
  routePath: '/metrics';
  selectedDateRangeKey: string;
  shortcuts: Record<string, QueryOpts>;
  table: {
    data: Record<string, number | string>[];
    isLoading: boolean;
  };
  title: string;
}

// Helper type when initializing the context state
export type CreateInitialState = MetricsContextState & {
  storage: {
    clear: () => void;
    getItem: (key: string) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
    removeItem: (key: string) => void;
    setItem: (key: string, data: string) => void;
  };
};

export interface MetricsContextProps {
  dispatch: React.Dispatch<MetricsReducerAction>;
  state: MetricsContextState;
}

export enum MetricsReducerActionTypes {
  query = 'query',
  reset = 'reset',
  resetMetricsOptions = 'resetMetricsOptions',
  set = 'set',
}

export interface MetricsSetPayload {
  payload: Partial<MetricsContextState>;
  type: MetricsReducerActionTypes.reset | MetricsReducerActionTypes.set;
}

export interface MetricsQueryPayload {
  payload: Record<string, unknown> | undefined;
  type: MetricsReducerActionTypes.query;
}

export interface MetricsResetMetricsOptionsPayload {
  payload: undefined;
  type: MetricsReducerActionTypes.resetMetricsOptions;
}

export type MetricsReducerAction = MetricsQueryPayload | MetricsResetMetricsOptionsPayload | MetricsSetPayload;

export interface MetricsProviderProps {
  children: React.ReactNode;
  usage: CustomerUsageData;
}
