import type {
  MetricsContextState,
  MetricsContextProps,
  MetricsReducerAction,
  MetricsProviderProps,
  CreateInitialState,
  CustomerUsageData,
} from '../types/props';
import type { $TSFixMe } from '@readme/iso';

import base64url from 'base64url';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import React, { useMemo, useReducer, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ProjectContext } from '@core/context';
import useLocalStorage from '@core/hooks/useLocalStorage';
import usePrevious from '@core/hooks/usePrevious';
import type { DateRangeKey, DateRangeOptions } from '@core/types/metrics';
import { safelyParseJSON, safelyStringifyJSON } from '@core/utils/json';

import useMetricsAPI from '@routes/Dash/Project/Metrics/hooks/useMetricsAPI';

import { MetricsReducerActionTypes } from '../types/props';

const MY_DEVELOPERS_ROUTE = '/metrics/developers';

const ALLOWED_GROUP_BYS = [
  'company',
  'email',
  'eventName',
  'groupId',
  'path',
  'period',
  'searchTerm',
  'status',
  'statusCategory',
  'type',
  'url',
  'useragent',
];

// If a customer is not paying us for metrics, block their usage of all ranges besides last 24 hours
const payingEnabled: DateRangeKey[] = ['week', 'month', 'quarter', 'year'];
export const dateRangeDefaults = {
  day: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 24,
    resolution: 'hour',
    enabled: true,
  },
  week: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 7,
    resolution: 'day',
    enabled: false,
  },
  month: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 30,
    resolution: 'day',
    enabled: false,
  },
  quarter: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 12,
    resolution: 'week',
    enabled: false,
  },
  year: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 12,
    resolution: 'month',
    enabled: false,
  },
} as DateRangeOptions;

/**
 * Helper function to get the selected date range key consistently from the date range options
 * Used in both MetricsContext and MyDevelopersContext
 */
export const getSelectedDateRangeKey = ({
  dateRanges,
  rangeLength,
  resolution,
}: {
  dateRanges: DateRangeOptions;
  rangeLength?: number | null;
  resolution?: string | null;
}) => {
  return (
    Object.keys(dateRanges).find(key => {
      const val = dateRanges[key as DateRangeKey];
      return val.resolution === resolution && val.rangeLength === rangeLength;
    }) || 'custom'
  );
};

/**
 * Helper function to preserve select query params when navigating between metrics pages
 */
export const preserveSelectedParams = (query: URLSearchParams) => {
  const preservedParams = [
    'userSearch',
    'rangeLength',
    'rangeStart',
    'rangeEnd',
    'resolution',
    'pageSize',
    'development',
    'tryItNow',
  ];

  const nextQueryParams = new URLSearchParams();

  preservedParams.forEach(param => {
    const value = query.get(param);
    if (value !== null) {
      nextQueryParams.set(param, value);
    }
  });

  return nextQueryParams;
};

// Helper function to create a URLSearchParams object from a given object
const createParams = (args = {}, state: MetricsContextState) => {
  const params = new URLSearchParams(state.query || '');

  Object.entries(args).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      params.delete(key);
    } else {
      params.set(key, String(value));
    }
  });

  return params;
};

const buildDefaultQueryParams = () => {
  // build default query params and incorporate any existing query params
  return new URLSearchParams({
    pageSize: '30',
    rangeLength: '30',
    resolution: 'day',
  });
};

const defaultMetricsOptions = {
  title: '',
  endpoint: null,
  filters: [],
  shortcuts: {},
  graph: { data: {}, isLoading: true },
  table: { data: [], isLoading: true },
  options: {
    graph: {
      noGroupByQuery: '',
      groupByBase: '',
      groups: [],
      query: '',
      type: '',
    },
    table: {
      columns: [],
      query: '',
    },
  },
  routeKey: '',
};

export const initialState: MetricsContextState = {
  query: new URLSearchParams(),
  /**
   * Whether or not fetched /requests/usage data is ready
   * Used to gate some metrics requests until usage data is ready
   * */
  isUsageReady: false,
  isFetchReady: {},
  routePath: '/metrics',
  dateRanges: null,
  selectedDateRangeKey: '',
  customerUsage: {
    limit: 0,
    explorer: {
      monthToDate: 0,
      twentyFourHour: 0,
      thirtyDay: 0,
    },
    sdk: {
      monthToDate: 0,
      twentyFourHour: 0,
      thirtyDay: 0,
    },
    overLimit: false,
    paying: false,
  },
  developmentData: null,
  includeTryItNow: null,
  /**
   * Defaults to true to prevent flashes for setup banners/CTAs components
   * Overidden once usage data is established
   * */
  isSetupComplete: true,
  ...defaultMetricsOptions,
};

/**
 * Helper function to create initial state for MetricsContext
 * with values we depend on immediately
 * */
function createInitialState({ storage }: CreateInitialState) {
  const metricsPrefs = safelyParseJSON(storage.getItem('metricsPrefs')) || {};
  const includeTryItNow = metricsPrefs.includeTryItNow ?? true;
  const developmentData = metricsPrefs.developmentData ?? false;

  // Build initial state w/ dependent values
  return {
    ...initialState,
    developmentData,
    includeTryItNow,
    query: buildDefaultQueryParams(),
  };
}

export const MetricsContext = React.createContext<MetricsContextProps>({ state: initialState, dispatch: () => {} });

const metricsReducer = (state = initialState, { type, payload }: MetricsReducerAction) => {
  switch (type) {
    case MetricsReducerActionTypes.query:
      return { ...state, query: createParams(payload, state) };
    case MetricsReducerActionTypes.set:
      return { ...state, ...merge(state, payload) };
    case MetricsReducerActionTypes.reset:
      return { ...state, ...payload };
    case MetricsReducerActionTypes.resetMetricsOptions:
      return {
        ...state,
        ...defaultMetricsOptions,
      };
    default:
      return state;
  }
};

export default function MetricsProvider({ usage, children }: MetricsProviderProps) {
  const storage = useLocalStorage();

  const [state, dispatch] = useReducer(metricsReducer, { storage } as CreateInitialState, createInitialState);

  const { pathname, search } = useLocation();
  const { project } = useContext(ProjectContext) as { project: $TSFixMe };
  const prevPathname = usePrevious(pathname) || '';

  /**
   * Support query filtering for groupBy and email params via query params
   * eg: `/page-views?userSearch=tony@readme.io`
   */
  useEffect(() => {
    if (!search) return;

    const searchParams = new URLSearchParams(search);
    const userSearch = searchParams.get('userSearch') ?? '';
    const groupBy = searchParams.get('groupBy') ?? '';
    const isEncoded = searchParams.get('encoded') === 'true';

    // Accept one or more groupBy params, and filter out any that aren't allowed
    const validGroupBys = groupBy.split(',').filter(param => ALLOWED_GROUP_BYS.includes(param));

    // groupBy params only should be applied to options.graph.query (used in /list and /historical fetches)
    if (validGroupBys.length) {
      const query = new URLSearchParams(state?.options.graph.query);
      const base = state?.options.graph?.groupByBase || false;
      const uniqueGroupBys = new Set<string>();

      // Add the valid groupBy params to the set
      validGroupBys.forEach(param => uniqueGroupBys.add(param));

      if (base) uniqueGroupBys.add(base);

      // Reset the ?groupBy param, and append the unique groupBy params
      query.delete('groupBy');
      uniqueGroupBys.forEach(param => query.append('groupBy', param));

      const graphQueryPayload = {
        options: {
          ...state.options,
          graph: {
            ...state.options.graph,
            query: query.toString(),
          },
        },
      };

      dispatch({ type: MetricsReducerActionTypes.set, payload: graphQueryPayload });
    }

    // userSearch param should only be applied to query params
    if (userSearch) {
      dispatch({
        type: MetricsReducerActionTypes.query,
        payload: {
          userSearch: isEncoded ? base64url.decode(userSearch) : userSearch,
        },
      });
    }
  }, [dispatch, search, state?.options]);

  useEffect(() => {
    const includeTryItNow = state.includeTryItNow;
    const developmentData = state.developmentData;

    const updateLocalStorage = (key: string, value: boolean) => {
      const json = safelyParseJSON(storage.getItem('metricsPrefs')) || {};
      const update = safelyStringifyJSON({
        ...json,
        [key]: value,
      });

      if (update !== null) {
        storage.setItem('metricsPrefs', update);
      }
    };

    if (includeTryItNow !== null) {
      dispatch({ type: MetricsReducerActionTypes.query, payload: { tryItNow: includeTryItNow } });
      updateLocalStorage('includeTryItNow', includeTryItNow);
    }

    if (developmentData !== null) {
      dispatch({ type: MetricsReducerActionTypes.query, payload: { development: developmentData } });
      updateLocalStorage('developmentData', developmentData);
    }
  }, [state.includeTryItNow, state.developmentData, storage]);

  // Core customer/company usage data
  // Block calls to get main metrics data until we have access to a customer's usage
  useEffect(() => {
    if (!usage) return;

    const { sdk } = usage;
    const limit = project?.metrics?.monthlyLimit || 0;

    const payload = {
      isUsageReady: true, // When usage is set, we can assume the async call to metrics has come back
      customerUsage: {
        limit,
        overLimit: limit > 0 && sdk?.monthToDate > limit,
        paying: limit > 0,
        ...usage,
      },
      // Whether to show setup banners/CTAs
      isSetupComplete: project?.onboardingCompleted.logs,
    };

    dispatch({ type: MetricsReducerActionTypes.set, payload });
  }, [usage, project?.metrics?.monthlyLimit, project?.onboardingCompleted.logs]);

  /**
   * Whether to limit (disable) all but "day" date range option
   * These conditions occur if we're on an API Request metrics graph, table or My Developers page
   * And the customer is not paying or over their allotment
   */
  const limitDateRanges = useMemo(() => {
    if (!state.isUsageReady) return false;

    const { paying, overLimit } = state.customerUsage;
    const notPayingOrOverlimit = !paying || overLimit;

    const isMyDevelopers = pathname.includes('metrics/developers');
    const isAPIMetricsPage = state.endpoint === 'requests';

    /**
     * For API Metrics pages + My Developers:
     * Limit Date Ranges to 24 hours if customer isn't paying, or
     * If they're paying but their over their purchased limit
     */
    return notPayingOrOverlimit && (isMyDevelopers || isAPIMetricsPage);
  }, [pathname, state.customerUsage, state.endpoint, state.isUsageReady]);

  // Build and set available custom date ranges calculated from customer usage
  useEffect(() => {
    if (!state.isUsageReady) return;

    const dateRanges = payingEnabled.reduce(
      (acc, resolution) => {
        acc[resolution].enabled = !limitDateRanges;
        return acc;
      },
      cloneDeep(dateRangeDefaults) as DateRangeOptions,
    );

    dispatch({ type: MetricsReducerActionTypes.set, payload: { dateRanges } });
  }, [limitDateRanges, state.isUsageReady]);

  // Reset date range selection when navigating away from My Developers page
  useEffect(() => {
    if (!state.isUsageReady) return;

    if (prevPathname.includes(MY_DEVELOPERS_ROUTE) && !pathname.includes(MY_DEVELOPERS_ROUTE)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enabled, ...queryPayload } = limitDateRanges ? dateRangeDefaults.day : dateRangeDefaults.month;
      dispatch({ type: MetricsReducerActionTypes.query, payload: queryPayload });
    }
  }, [limitDateRanges, pathname, prevPathname, state.isUsageReady]);

  // Update date range selection based on graph type + permissions
  useEffect(() => {
    if (!state.isUsageReady) return;

    // If limited, set date range to "day" and remove enabled key (as it's only relevant for MetricsDateRange component)
    if (limitDateRanges) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enabled, ...queryPayload } = dateRangeDefaults.day;
      dispatch({ type: MetricsReducerActionTypes.query, payload: queryPayload });
    }

    // Set isFetchReady to true for the current route
    if (state.routeKey) {
      dispatch({
        type: MetricsReducerActionTypes.set,
        payload: { isFetchReady: { [state.routeKey]: true } },
      });
    }
  }, [limitDateRanges, state.isUsageReady, state.routeKey]);

  // Update selectedDateRangeKey based on query params
  useEffect(() => {
    if (!state.dateRanges) return;

    const rangeLength = Number(state.query.get('rangeLength')) || 0;
    const resolution = state.query.get('resolution') || '';

    const key = getSelectedDateRangeKey({ dateRanges: state.dateRanges, rangeLength, resolution });

    dispatch({ type: MetricsReducerActionTypes.set, payload: { selectedDateRangeKey: key } });
  }, [state.dateRanges, state.query]);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <MetricsContext.Provider value={value}>{children}</MetricsContext.Provider>;
}

/**
 * @visibleName MetricsProviderWithUsage
 * @description A wrapper for MetricsProvider that fetches usage data for routes requiring it
 */
export const MetricsProviderWithUsage = ({ children }: { children: React.ReactNode }) => {
  const { data } = useMetricsAPI<CustomerUsageData>('requests/usage');

  return <MetricsProvider usage={data}>{children}</MetricsProvider>;
};
